import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import swal from 'sweetalert';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportService } from '../../service/report.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-schedule-report',
  templateUrl: './schedule-report.component.html',
  styleUrls: ['./schedule-report.component.scss'],
  providers: [DatePipe]

})
export class ScheduleReportComponent implements OnInit {
  @ViewChild('instance2', { static: true }) instance2: NgbTypeahead;
  @ViewChild('myModal') public myModal: ModalDirective;
  pageNo = 0;
  myDate = new Date();
date:any;
 

  customerIdNameList: { id: number, name: string }[] = [];
  customerControl = new FormControl();
  // customerList = [];
  focus2$ = new Subject<string>();
  click2$ = new Subject<string>();
  searchTerm: string = '';
  itemsPerPage = 10;
  filterString = new FormControl('');
  report: any = [];
  filterReport: any[] = [];
  reportList: any = [];


  reportLength: any = [];
  model: any;
  searching = false;
  searchFailed = false;
  profileUrl = '/assets/img/avatars/avatar.png';
  manDate: moment.Moment;
  searchForm: FormGroup;

  constructor(
    private reportService: ReportService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private fb: FormBuilder

  ) { 
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.new_searchForm();
  }

  ngOnInit(): void {
    this.spinner.show();
    // this.customerService.getAll().subscribe((data: any) => {
    //   this.customer = data.data;
    //   // this.customer.map(item => {
    //   //   let p = {
    //   //     'id': item.id,
    //   //     'name': item.name
    //   //   }
    //   //   this.customerIdNameList.push(p);
    //   // })
    //   this.filterCustomer = data.data;
    //   this.spinner.hide();
    // }, (err) => this.error_c(err))

    // this.spinner.hide();
    this.serachSubmit(0);
    this.spinner.hide();

  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  new_searchForm() {
    this.searchForm = this.fb.group({
      fromDate: [this.date],
      toDate: [this.date]
    });
  }

  serachSubmit(pgnao) {
    this.pageNo = pgnao;
    
    this.spinner.show()
    this.pageNo;
    this.itemsPerPage;
    this.reportService.getScheduleReport(this.searchForm.get('fromDate').value,this.searchForm.get('toDate').value,).subscribe(data => {
      this.reportList = data.data;
      // let   manDate='';
      this.reportList.map(item =>{

        this.manDate = moment(item.startTime, "HH:mm:ss")
      let   printS =   this.manDate.format('hh:mm A');

      let  manDate = moment(item.endTime, "HH:mm:ss")
      let  printO = manDate.format('hh:mm A');
      
      item.endTime = printO;
      item.startTime = printS;

        // item.profilePhoto = this.sanitize(item.profilePhoto);
        })
      
      // this.reportLength = data.data.length();
      debugger
      this.spinner.hide()
    });
    this.spinner.hide()
  }


  

 

  

  // onFilterChange(x) {
  //   console.log(this.filterString.value)
  //   debugger
  //   this.filterCustomer = this.customer.filter((invoice) => this.isMatch(invoice));
  // }

  // isMatch(item) {

  //   if (item instanceof Object) {
  //     return Object.keys(item).some((k) => this.isMatch(item[k]));
  //   } else {

  //     return item.toString().indexOf(this.filterString.value) > -1

  //   }
  // }
  // applyFilter(filterValue: string) {
  //   this.spinner.show();
  //   this.pageNo = 0;

  //   let filterValueLower = filterValue.toLowerCase().trim();
  //   // debugger
  //   if (filterValueLower === "") {
  //     this.filterCustomer = this.customer;
  //   }
  //   else {
  //     this.filterCustomer = this.customer.filter((item) => item.name.toLowerCase().includes(filterValueLower))
  //   }
  //   this.spinner.hide();
  // }
  pageNoChange($event) {
    this.pageNo = $event - 1;
    this.serachSubmit( this.pageNo);
  }

  error_c(err) {
    this.spinner.hide();
    if (String(err.error.message).length > 0 && !String(err.error.message) == undefined) {
      debugger
      this.toastr.error(err.error.message, 'Alert!');
    }
    else {
      this.toastr.error("Technical Issue", 'Alert!');
    }
  }
}
