import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
} from '@angular/router';
import { isEmpty } from 'rxjs/operators';
import { TokenStorageService } from './token-storage.service';
// import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private tokenStorage: TokenStorageService, private router: Router,) { }

  canActivate() {
    const currentUser = this.tokenStorage.getUser();
    if (this.isEmptyObject(currentUser)) {
      
      return false;
    } else {
      return true;
    }
    // logout();
  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }
}


