import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from '../../app/common/global';
// import { CustomerModel } from '../model/customer-model';
import { ResponseData } from '../model/response';
import { Users } from '../model/users';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient, private global: Globals) { }

  create(data: any) {
    return this.httpClient.post<ResponseData>(this.global.api_Url + this.global.user, data)
  }

  changePassword(data: any){
    return this.httpClient.post<ResponseData>(this.global.api_Url + this.global.user + '/' + this.global.change_password, data)
  }
  
  getAll() {
    return this.httpClient.get<Users[]>(this.global.api_Url + this.global.user)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }

  delete(id: number) {
    //  debugger
    return this.httpClient.delete<Users>(this.global.api_Url + this.global.user + '/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    // )
  }

  update(id, data) {
    return this.httpClient.put<ResponseData>(this.global.api_Url + this.global.user + '/' + id, data)
  }

  getbyid(id: any) {
    return this.httpClient.get<Users[]>(this.global.api_Url + this.global.user + '/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }



  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
