import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CustomerModel } from '../../../model/customer-model';
import { AssignSubscriptionService } from '../../../service/assign-subscription.service';
import { CustomerService } from '../../../service/customer.service';
import { ReceiptService } from '../../../service/receipt.service';
import { TokenStorageService } from '../../../service/token-storage.service';

@Component({
  selector: 'app-manage-receipt',
  templateUrl: './manage-receipt.component.html',
  styleUrls: ['./manage-receipt.component.scss'],
  providers: [DatePipe]
})
export class ManageReceiptComponent implements OnInit {
  @ViewChild('instance2', { static: true }) instance2: NgbTypeahead;

  customerIdNameList: { id: number, name: string }[] = [];
  customerControl = new FormControl();
  duesControl = new FormControl();
  focus2$ = new Subject<string>();
  click2$ = new Subject<string>();
  pageNo = 0;
  itemsPerPage = 10;
  filterString = new FormControl('');
  customer: CustomerModel[] = [];
  formReceipt: FormGroup;
  receiptNo: number = 0;
  userId: number;
  userName: string;
  date: string;
  myDate = new Date();
  constructor(
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private customerService: CustomerService,
    private router: Router,
    private _Activatedroute: ActivatedRoute,
    private receiptService: ReceiptService,
    private assignService: AssignSubscriptionService
  ) {
    this.userName = this.tokenStorage.getUser().name;
    this.userId = this.tokenStorage.getUser().id;
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.receiptForm();
  }

  ngOnInit(): void {
    this.getReceiptNo();
    const id = this._Activatedroute.snapshot.paramMap.get('id') ? this._Activatedroute.snapshot.paramMap.get('id') : 0;
    this.getdues(id);
  }

  getReceiptNo() {
    this.receiptService.getReceiptNo().subscribe(res => {
      this.receiptNo = res | 0;
      this.formReceipt.get('receiptNo').setValue(this.receiptNo)
      this.spinner.hide();
    }, (err) => this.error_c(err)
    )
  }

  getdues(id) {
    this.assignService.getDuesById(id).subscribe(res => {
      let dues = res.data.dueAmount | 0;
      debugger
      this.formReceipt.get('customerId').setValue(res.data.customerId);
      this.formReceipt.get('customerName').setValue(res.data.customerName)
      this.duesControl.setValue(dues);
      this.spinner.hide();
    }, (err) => this.error_c(err)
    )
  }

  receiptForm() {
    this.formReceipt = this.fb.group({
      id: [0],
      customerId: ['', Validators.required],
      customerName: ['', Validators.required],
      paidAmount: [0, [Validators.required, Validators.pattern("[0-9]*")]],
      paymentDetails: [''],
      paymentMode: ['Cash'],
      receiptDate: [this.date, Validators.required],
      receiptNo: [this.receiptNo, [Validators.required, Validators.pattern("[0-9]*")]],
      userId: [this.userId, Validators.required],
      userName: [this.userName, Validators.required]
    });
  }

  onSubmit() {
    let data = this.formReceipt.value;
      this.receiptService.create(data).subscribe(res => {
        if (res.success) {
          this.toastr.success('Successfully Saved!', 'Notification!');
          this.router.navigateByUrl('/receipt/all-receipt');
        }
        else {
          this.toastr.error(res.message, 'Notification!');
        }
        this.spinner.hide();
      }, (err) => this.error_c(err)
      )
    
  }

  error_c(err) {
    this.spinner.hide();
    if (String(err.error.message).length > 0 && !String(err.error.message) == undefined) {
      this.toastr.error(err.error.message, 'Alert!');
    }
    else {
      this.duesControl.setValue(0)
      this.toastr.error("Technical Issue", 'Alert!');
    }
  }


  search: OperatorFunction<string, readonly { id, name }[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click2$.pipe(filter(() => !this.instance2.isPopupOpen()));
    const inputFocus$ = this.focus2$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term.length < 2 ? []
        : this.getcustomer(term).filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
    );
  }
  getcustomer(term) {
    //  if (term.length > 3){
    console.log(term.length)
    this.customerService.getbyterm(term).subscribe((data: any) => {
      this.customer = data.data;
      this.customerIdNameList = [];
      this.customer.map(item => {
        let p = {
          'id': item.id,
          'name': item.name
        }
        this.customerIdNameList.push(p);
      })

      this.spinner.hide();
    }, (err) => this.error_c(err))
    return this.customerIdNameList;
    // }
  }

  formatterCustomer = (x: { name: string }) => x.name;


  populateCustomerValue = () => {
    if (this.customerIdNameList.length > 0) {
      if (this.formReceipt.get('customerId').value.id) {
        // this.formReceipt.get('customerId').setValue(this.customerControl.value.id);
        this.getdues(this.formReceipt.get('customerId').value.id);
      }
    }
  }

  enterAmount(){
    if(this.formReceipt.get('paidAmount').value != ''){
      if(this.formReceipt.get('paidAmount').value > this.duesControl.value){
        this.toastr.error('Please enter less amount', 'Alert!');
        this.formReceipt.get('paidAmount').setValue(0);
      }else{

      }

    }
  }

}
