import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NgSelect2Module } from 'ng-select2';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
  
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { CustomerComponent } from './views/customer/customer.component';
import { SubscriptionsComponent } from './views/subscriptions/subscriptions.component';
import { AccessoriesComponent } from './views/accessories/accessories.component';
import { ScheduleComponent } from './views/schedule/schedule.component';
import { SettingComponent } from './views/setting/setting.component';
import { TicketComponent } from './views/ticket/ticket.component';
import { NgSelect2Module } from 'ng-select2';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { Globals } from './common/global';
import { NgxPaginationModule } from 'ngx-pagination';
import { EntryPointComponent } from './views/entry-point/entry-point.component';
import { WebcamModule } from 'ngx-webcam';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UsersComponent } from './views/users/users.component';
import { ReceiptComponent } from './views/receipt/receipt.component';
import { ManageReceiptComponent } from './views/receipt/manage-receipt/manage-receipt.component';
import { AllReceiptComponent } from './views/receipt/all-receipt/all-receipt.component';
import { ReportComponent } from './views/report/report.component';
import { ScheduleReportComponent } from './views/schedule-report/schedule-report.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    NgSelect2Module,
    WebcamModule,
    ReactiveFormsModule,
    ModalModule,
    FormsModule,
    NgbAlertModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    NgxSpinnerModule,
    HttpClientModule,
    NgxPaginationModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    IconSetModule.forRoot(),
    NgbModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    CustomerComponent,
    SubscriptionsComponent,
    AccessoriesComponent,
    ScheduleComponent,
    SettingComponent,
    TicketComponent,
    EntryPointComponent,
    UsersComponent,
    ReceiptComponent,
    ManageReceiptComponent,
    AllReceiptComponent,
    ReportComponent,
    ScheduleReportComponent
    
  ],
  providers: [
    FormBuilder,Globals,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy

    },
    IconSetService,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
