import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../../service/token-storage.service';
import { UserLoginService } from '../../service/user-login.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  loginType = new FormControl('USER');
  formGroupLogin: FormGroup;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private userLoginService: UserLoginService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService
  ) {

    this.my_form();
  }

  my_form() {
    this.formGroupLogin = this.fb.group({
      id: [0],
      password: ['', Validators.required],
      mobileNo: [null, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
    });
  }

  onSubmit() {

    this.spinner.show();
    // console.log(this.loginType.value);

    // if (this.loginType.value === "USER") {

      this.userLoginService.login(this.formGroupLogin.value).subscribe(res => {

        if (res.success) {
          debugger
          this.toastr.success('Successfully Login!', 'Notification!');
          this.tokenStorage.saveUser(res.data);
          if(res.data.id == 1){
            window.sessionStorage.setItem('role', 'ADMIN');
            
          }else{
            window.sessionStorage.setItem('role', 'USER');
          }

          this.router.navigateByUrl('/dashboard');
        }
        else {
          this.toastr.error(res.message, 'Notification!');
        }

        this.spinner.hide();

      }, (err) => this.error_c(err)
      )

    // }

    // if (this.loginType.value === "ADMIN") {
    //   this.userLoginService.adminlogin(this.formGroupLogin.value).subscribe(res => {
    //     if (res.success) {

    //       this.toastr.success('Successfully Login!', 'Notification!');
    //       this.tokenStorage.saveUser(res.data);
    //       window.sessionStorage.setItem('role', this.loginType.value);
    //       this.router.navigateByUrl('/dashboard');
    //     }
    //     else {
    //       this.toastr.error(res.message, 'Notification!');
    //     }
    //     this.spinner.hide();
    //   }, (err) => this.error_c(err)
    //   )

    // }

    this.spinner.show();

  }

  error_c(err) {
    this.spinner.hide();
    if (String(err.error.message).length > 0 && !String(err.error.message) == undefined) {
      this.toastr.error(err.error.message, 'Alert!');
    }
    else {
      this.toastr.error("Technical Issue", 'Alert!');
    }
  }
}
