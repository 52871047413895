import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

Injectable()
export class Globals {
   constructor() { }
    // public api_Url = "http://192.168.0.29:8181/ticket/";
    public api_Url = "http://swimmingpool.visionitsoftware.in:8181/ticket/";
    // public api_Url = "http://localhost:8181/ticket/";
    // public api_Url = "http://192.168.0.13:8181/ticket/";

    public customer = 'customer';
    public accessories = 'accessories';
    public schedules = 'schedules';
    public subscription = 'subscription';
    public setting = 'setting';
    public rate = 'rate';
    public companyProfile = "profile";
    public customer_term = "customer-info";
    public customer_mobile = "customer-by-mobile";
    public assign_subscription = "assign-subscription";
    public user = "user";
    public change_password = "change/password";

    public admin = "admin";
    public user_login = "user-login";
    public admin_login = "login";
    public adminChangePassword = "change/password";
    public receipt = "receipt";
    public generate_receipt_no = "generate-receipt-no";
    public get_due_by_customer = "get-due-by-customer";
    public ticket = "ticket";
    public customerDoc = "customer-documents";
    public get_subscription_by_customer_user_status = "get-subscription-by-customer-user-status";
    public report = "report";
    public schedule_report = "schedule-wise-report";

    public dashboard = "dashboard";


    public getHTTPHeaders(): HttpHeaders {
        let result = new HttpHeaders();
        result = result.set('Content-Type', 'application/json');
        result = result.set('Access-Control-Allow-Headers', 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With,observe"');
        result = result.set('Access-Control-Allow-Methods', 'POST, PUT, GET, OPTIONS, DELETE');
        result = result.set('Access-Control-Allow-Origin', '*');
        // result = result.set('Authorization', accessToken);
        // result = result.set('sessionid', sessionId);
        return result;
      }
}

