import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from '../../app/common/global';
import { CustomerModel } from '../model/customer-model';
import { ResponseData } from '../model/response';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {

  constructor(private httpClient: HttpClient, private global: Globals) { }

  create(data: any) {
    return this.httpClient.post<ResponseData>(this.global.api_Url + this.global.receipt, data)
  }

  getAll(pageNo, itemsPerPage, data): Observable<any> {
    if (  data !== null ) {
      return this.httpClient.get(this.global.api_Url + this.global.receipt + '?pageNo=' + pageNo + '&pageSize=' + itemsPerPage+ '&searchTerm=' + data);
    }else{
     
      return this.httpClient.get(this.global.api_Url + this.global.receipt + '?pageNo=' + pageNo + '&pageSize=' + itemsPerPage);
    }
  }

  getallData(fromDate, toDate): Observable<any> {
    return this.httpClient.get(this.global.api_Url + this.global.receipt + '?fromDate=' + fromDate + '&toDate=' + toDate);
  }




  delete(id: number) {
    //  debugger
    return this.httpClient.delete<CustomerModel>(this.global.api_Url + this.global.receipt + '/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    // )
  }

  update(id, data) {
    return this.httpClient.put<ResponseData>(this.global.api_Url + this.global.receipt + '/' + id, data)
  }

  getbyid(id: any) {
    return this.httpClient.get<CustomerModel[]>(this.global.api_Url + this.global.receipt + '/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }
  getReceiptNo(): Observable<any> {
    return this.httpClient.get(this.global.api_Url + this.global.receipt + '/' + this.global.generate_receipt_no)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }


  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
