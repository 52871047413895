import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CompanyProfileService } from '../../service/customer.service.spec';
import { SettingsRateService } from '../../service/settings-rate.service';
import { AdminService } from '../../service/admin.service';
import { TokenStorageService } from '../../service/token-storage.service';
import { UserService } from '../../service/user.service';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  profile: File;

  formGroupRate: FormGroup;
  formGroupProfile: FormGroup;
  formpassword: FormGroup;

  id: number = 0;
  isImageInvalid: boolean;
  logo: File;
  userId: number = 0;

  imgurl: string | ArrayBuffer = '/assets/img/avatars/avatar.png';

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private settingRateService: SettingsRateService,
    private tokenStorage: TokenStorageService,
    private companyProfileService: CompanyProfileService,
    private adminService: AdminService,
    private userService: UserService
  ) {
    this.isImageInvalid = false;
    this.userId = this.tokenStorage.getUser().id;
  }

  ngOnInit(): void {

    this.profile_form();

    this.my_form();

    this.load_rate();

    this.changePasswordForm();

    this.load_profile();

  }

  load_rate() {
    this.settingRateService.getAll().subscribe((data: any) => {
      if (data.data[0]) {
        this.formGroupRate.setValue(data.data[0]);
      }
    }, (err) => this.error_c(err))
  }

  load_profile() {
    this.companyProfileService.getAll().subscribe((data: any) => {
      debugger
      if (data.data[0]) {
        this.formGroupProfile.setValue(data.data[0]);
        debugger
        if (data.data[0].logo == '' || data.data[0].logo == null) {
          this.imgurl = this.formGroupProfile.get('logo').value
        } else {
          this.imgurl = data.data[0].logo
        }
      }
    }, (err) => this.error_c(err))
  }

  profile_form() {
    this.formGroupProfile = this.fb.group({
      id: [0],
      name: ['', Validators.required],
      mobileNo: [null, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
      address: [null],
      logo: [''],
    });
  }

  changePasswordForm() {
    this.formpassword = this.fb.group({
      id: [this.userId],
      newPassword: ['', Validators.required],
      oldPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });


  }

  my_form() {
    this.formGroupRate = this.fb.group({
      id: [0],
      regularDays: [0, [Validators.required, Validators.pattern("[0-9]*")]],
      holidays: [0, [Validators.required, Validators.pattern("[0-9]*")]],
      // miniutes: [0, [Validators.required, Validators.pattern("[0-9]*")]]
    });
  }

  uploadFiles($event) {
    this.isImageInvalid = false;
    let file = $event.target.files[0];
    console.log(file.size);
    if (file.size > 5242880) {
      this.isImageInvalid = true;
      this.toastr.error('Size is Greater than 5MB', 'Image');
    }
    else {
      this.profile = file;
      //console.log(this.profile);
      var reader = new FileReader();
      reader.readAsDataURL(this.profile); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgurl = reader.result;
      }
    }
  }
  rateSubmit() {

    this.spinner.show();
    this.id = this.formGroupRate.get('id').value;

    if (this.id > 0) {
      this.settingRateService.update(this.id, this.formGroupRate.value).subscribe(res => {
        if (res.success) {
          this.toastr.success('Successfully Updated!', 'Notification!');
        }
        else {
          this.toastr.error(res.message, 'Notification!');
        }
        this.spinner.hide();
      }, (err) => this.error_c(err)
      )
    }
    else {
      this.settingRateService.create(this.formGroupRate.value).subscribe(res => {

        if (res.success) {
          this.toastr.success('Successfully Saved!', 'Notification!');
          this.load_profile();

        }
        else {
          this.toastr.error(res.message, 'Notification!');
        }
        this.spinner.hide();
      }, (err) => this.error_c(err)
      )
    }
  }

  passwordSubmit() {
    if (this.formpassword.get('newPassword').value === this.formpassword.get('confirmPassword').value) {
      this.spinner.show();
      this.userService.changePassword(this.formpassword.value).subscribe(res => {
        this.toastr.success('Successfully Updated!', 'Notification!');
        this.changePasswordForm();
        this.spinner.hide();
      }, (err) => this.error_c(err)
      )
    } else {
      this.toastr.warning('Plese confirm password!', 'Notification!');
    }
  }


  profileSubmit() {
    this.spinner.show();
    this.formGroupProfile.get('id').value;
    if (this.id > 0) {
      if (this.isImageInvalid) {
        this.toastr.error('Size is Greater than 5MB', 'Image');
      }
      else {
        let data = this.formGroupProfile.value;
        delete data.logo;
        const formData: FormData = new FormData();

        formData.append('logo', this.profile);
        formData.append('profileDto ', JSON.stringify(data));

        this.companyProfileService.update(this.id, formData).subscribe(res => {

          if (res.success) {
            this.toastr.success('Successfully Saved!', 'Notification!');
            this.load_profile();
            window.location.reload()
          }
          else {
            this.toastr.error(res.message, 'Notification!');
          }

          this.spinner.hide();

        }, (err) => this.error_c(err)
        )

      }
    }
    else {
      if (this.isImageInvalid) {
        this.toastr.error('Size is Greater than 5MB', 'Image');
      }
      else {
        const formData: FormData = new FormData();

        formData.append('logo', this.profile);
        formData.append('profileDto ', JSON.stringify(this.formGroupProfile.value));

        this.companyProfileService.create(formData).subscribe(res => {
          if (res.success) {
            this.toastr.success('Successfully Saved!', 'Notification!');
          }
          else {
            this.toastr.error(res.message, 'Notification!');
          }

          this.spinner.hide();

        }, (err) => this.error_c(err)
        )

        // this.toastr.success('Successfully saved', 'Customer');

        // this.my_form();
      }
    }
  }


  error_c(err) {
    this.spinner.hide();
    if (String(err.error.message).length > 0 && !String(err.error.message) == undefined) {
      this.toastr.error(err.error.message, 'Alert!');
    }
    else {
      this.toastr.error("Technical Issue", 'Alert!');
    }
  }

}
