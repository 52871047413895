import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from '../../app/common/global';
// import { CustomerModel } from '../model/customer-model';
import { ResponseData } from '../model/response';
import { Users } from '../model/users';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpClient: HttpClient, private global: Globals) { }

  
  // adminlogin(data: any) {
  //   return this.httpClient.post<any>(this.global.api_Url + this.global.admin + '/' + this.global.admin_login, data)
  // }
  update( data) {
    return this.httpClient.post<ResponseData>(this.global.api_Url + this.global.admin + '/' + this.global.adminChangePassword , data)
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
