import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from '../../app/common/global';
import { CustomerModel } from '../model/customer-model';
import { ResponseData } from '../model/response';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private httpClient: HttpClient, private global: Globals) { }

  create(data: any) {
    return this.httpClient.post<ResponseData>(this.global.api_Url + this.global.customer, data)
  }

  getAll(pageNo, itemsPerPage, data, isPagination): Observable<any> {
    if (data !== null) {
      return this.httpClient.get(this.global.api_Url + this.global.customer + '?pageNo=' + pageNo + '&pageSize=' + itemsPerPage + '&searchTerm=' + data + '&IsPagination=' + isPagination);
    } else {

      return this.httpClient.get(this.global.api_Url + this.global.customer + '?pageNo=' + pageNo + '&pageSize=' + itemsPerPage + '&IsPagination=' + isPagination);
    }
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }



  delete(id: number) {
    //  debugger
    return this.httpClient.delete<CustomerModel>(this.global.api_Url + this.global.customer + '/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    // )
  }

  update(id, data) {
    return this.httpClient.put<ResponseData>(this.global.api_Url + this.global.customer + '/' + id, data)
  }

  getbyid(id: any) {
    return this.httpClient.get<CustomerModel[]>(this.global.api_Url + this.global.customer + '/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }


  get_customerDocuments(id: number): Observable<any> {
    return this.httpClient.get(this.global.api_Url + this.global.customer + '/' + this.global.customerDoc + '?customerId=' + id + '&id=0')
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }
  getbyterm(term: string) {
    return this.httpClient.get<CustomerModel[]>(this.global.api_Url + this.global.customer + '/' + this.global.customer_term + '?name=' + term)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }
  getbyMobile(mobile: string) {
    return this.httpClient.get<CustomerModel[]>(this.global.api_Url + this.global.customer + '/' + this.global.customer_mobile + '?mobileNo=' + mobile)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
