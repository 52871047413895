import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from '../../app/common/global';
import { ResponseData } from '../model/response';

@Injectable({
  providedIn: 'root'
})
export class TicketGenerationService {

  constructor(private httpClient: HttpClient, private global: Globals) { }

  create(data: any):Observable<any> {
    return this.httpClient.post(this.global.api_Url + this.global.ticket, data)
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
