import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Select2OptionData } from 'ng-select2';

import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScheduleService } from '../../service/schedule.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../service/customer.service';
import { SubscriptionService } from '../../service/subscription.service';
import { SettingsRateService } from '../../service/settings-rate.service';
import { WebcamImage } from 'ngx-webcam';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { AccessoriesService } from '../../service/accessories.service';
import { AccessoriesModel } from '../../model/accessories-model';
import { WalkinAccesories } from '../../model/walkin-accesories';
import { Rates } from '../../model/rates';
import { DatePipe } from '@angular/common';
import { StringifyOptions } from 'querystring';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CustomerModel } from '../../model/customer-model';
import { AssignSubscriptionService } from '../../service/assign-subscription.service';
import { TokenStorageService } from '../../service/token-storage.service';
import { TicketGenerationService } from '../../service/ticket-generation.service';
import { ReceiptService } from '../../service/receipt.service';
import { CompanyProfileService } from '../../service/customer.service.spec';
declare var $;


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  providers: [DatePipe]
})
export class TicketComponent implements OnInit {
  @ViewChild("myinput") myInputField: ElementRef;
  @ViewChild('primaryModal') public primaryModal: ModalDirective;
  @ViewChild('instance2', { static: true }) instance2: NgbTypeahead;
  @ViewChild('closebutton', { static: false }) closebutton;
  @ViewChild('smallModal') public smallModal: ModalDirective;
  @ViewChild('walkinPrint') walkinPrint: ElementRef;

  customerId: number = 0;
  customerIdNameList: { id: number, name: string }[] = [];
  customerControl = new FormControl('', Validators.required);//Validators.required
  pay = new FormControl('unpaid');
  myDate = new Date();
  rateType: string = "Regular";
  subscriptionType: string;
  subscriptionValidity: number;
  subscriptionAmount: number;
  subscriptionDaysVisits: number;
  endTime: string;
  walkcustomerId: number;
  walkcustomerName: string
  userId: number;
  userName: string;
  holidayRate: number = 0;
  regularRate: number = 0;
  subHelp: boolean = false;
  time = new Date();
  qty: number = 1;
  minutes: number = 0;
  receiptNo: number = 0;
  inTime: string;
  walkQrCode;
  outTime: string;
  webCam: boolean = false;
  snapshot: boolean = false;
  webCam1: boolean = false;
  snapshot1: boolean = false;
  public webcamImage: WebcamImage = null;
  public webcamImage1: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  date: string;
  selectedFile: File = null;
  uploadImgSrc: any;
  uploadShowImg: boolean = false;
  file: File;
  imgurl: string | ArrayBuffer = '/assets/img/avatars/avatar.png';
  imgurlPrint: string | ArrayBuffer = '/assets/img/avatars/avatar.png';
  imgurlLogoPrint: string | ArrayBuffer = '/assets/img/avatars/swimming_logo.jpg';

  imgname: string[];
  event: any;
  fileErr: boolean;
  files: File[] = [];
  focus2$ = new Subject<string>();
  click2$ = new Subject<string>();
  ratesList: Rates[] = [];
  logo: string = '';
  public scheduleData: Array<Select2OptionData>;
  public exampleData: Array<Select2OptionData>;
  public customerData: Array<Select2OptionData>;
  public subscriptionData: Array<Select2OptionData>;

  public isSavePass: boolean = false;
  public passCustomerName: string;
  public passCustomerMobile: string;
  scheduleList: any = [];
  customerList: any;
  subscriptionList: any;
  scheduleTime: string;
  passSubscription: string;
  customerImgpass: any;
  passBarcode: any;

  optionsSelect = {
    placeholder: "Select..",
    allowClear: true,
    width: "100%"
  }
  paymentForm: FormGroup;
  formGroupPass: FormGroup;
  formGroupWalkIn: FormGroup;
  formGroupCustomer: FormGroup;
  formGroupProfile: FormGroup;
  walkInPrint: FormGroup;
  ticketJoinDto: FormArray;
  accessories: AccessoriesModel[] = [];
  accessoriesList: WalkinAccesories[] = [];
  accessoriesListAdded: WalkinAccesories[] = [];
  payment: boolean = false;
  entryFess: number;
  ticketTotal: number;
  datePrint: string;
  printTime: string;
  startdatePrint: any;
  enddatePrint: any;
  customer = new FormControl()

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private scheduleService: ScheduleService,
    private toastr: ToastrService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private subscriptionService: SubscriptionService,
    private settingRateService: SettingsRateService,
    private accessoriesService: AccessoriesService,
    private tokenStorage: TokenStorageService,
    private assignSubscriptionService: AssignSubscriptionService,
    private ticketService: TicketGenerationService,
    private receiptService: ReceiptService,
    private companyProfileService: CompanyProfileService,
  ) {

    NgbTypeahead.prototype.dismissPopup = function () {
      if (this.isPopupOpen()) {
        this._closePopup();
        if (this._elementRef.nativeElement.value !== '') {
          this._writeInputValue(this._inputValueBackup);
        }
      }
    }
    this.profile_form();
    this.userName = this.tokenStorage.getUser().name;
    this.userId = this.tokenStorage.getUser().id;

    this.rateType = this.tokenStorage.getRateType();
    if (this.rateType == null) {
      this.rateType = "Regular";
      debugger
    }

    // this.time.toLocaleTimeString();
    var momentObj = moment(this.time.toLocaleTimeString(), ["h:mm A"]);

    this.inTime = momentObj.format("HH:mm");

    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.startdatePrint = this.datePipe.transform(this.myDate, 'dd-MM-yyyy');
    this.datePrint = this.datePipe.transform(this.myDate, 'dd-MM-yyyy');


    this.new_Passform();

    this.new_WalkInform();

    this.nee_walkInPrint();

    this.new_Customer();

  }



  ngOnInit(): void {

    //this.spinner.show();

    this.formGroupPass.get('startDate').valueChanges.subscribe(x => this.calcUptoDate(x));
    this.formGroupPass.get('subscriptionId').valueChanges.subscribe(x => this.subscriptionChange(x));
    this.formGroupPass.get('schedulesId').valueChanges.subscribe(x => this.schedulesIdChange(x));

    // this.formGroupWalkIn.get('startTime').valueChanges.subscribe(x => this.startTimeChange(x));
    this.formGroupPass.get('discount').valueChanges.subscribe(x => this.discountChange(x));
    this.formGroupCustomer.get('dateOfBirth').valueChanges.subscribe(value => this.dateOfBirthonChange(value));
    // this.load_customer();

    this.load_schedules();

    this.load_subscriptions();

    this.get_rate();

    // this.startTimeChange(this.formGroupWalkIn.get('startTime').value);
    // let startTime = this.formGroupWalkIn.get('startTime').value;
    // debugger
    this.load_profile();

    this.getAllAccesories();

    this.totAmount();

  }

  load_profile() {
    this.companyProfileService.getAll().subscribe((data: any) => {
      if (data.data[0]) {
        this.formGroupProfile.setValue(data.data[0]);
        this.logo = this.formGroupProfile.get('logo').value
      }
    }, (err) => this.error_c(err))
  }

  profile_form() {
    this.formGroupProfile = this.fb.group({
      id: [0],
      name: ['', Validators.required],
      mobileNo: [null, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
      address: [null],
      logo: [''],
    });
  }

  nee_walkInPrint() {
    this.walkInPrint = this.fb.group({
      name: ['', Validators.required],
      ticketTotal: [0, Validators.required],
      // startTime: [this.inTime, Validators.required],
      // endTime: [this.endTime, Validators.required],
      // minutes: [this.minutes, Validators.required],
      schedulesId: ['', Validators.required],
      walkQrCode: ['', Validators.required],
      endTime: ['', Validators.required],
      startTime: ['', Validators.required],
      mobileNo: [null, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]]
    });
  }

  dateOfBirthonChange(value) {
    debugger
    const convertAge = new Date(value);
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    let showAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    this.formGroupCustomer.get('age').setValue(showAge);
  }

  getCustomer() {
    debugger
    let mobileNo = this.formGroupWalkIn.get('mobileNo').value
    if (mobileNo.length <= 10) {

      this.customerService.getbyMobile(this.formGroupWalkIn.get('mobileNo').value).subscribe((data: any) => {
        this.customerList = data.data
        this.customerIdNameList = [];
        data.data.map(item => {
          let p = {
            'id': item.id,
            'name': item.name
          }
          this.customerIdNameList.push(p);
        })

        this.spinner.hide();
      }, (err) => this.error_c(err))
    } else {
      this.toastr.error('Please enter 10 digit', 'Notification!');
    }

  }

  search: OperatorFunction<string, readonly { id, name }[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click2$.pipe(filter(() => !this.instance2.isPopupOpen()));
    const inputFocus$ = this.focus2$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.customerList
        : this.customerList.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
    );
  }


  formatterCustomer = (x: { name: string }) => x.name;

  populateWalkCustomerValue = () => {
    // console.log(this.customerIdNameList.length)
    debugger
    if (this.customerList.length > 0) {
      const customer = this.customerList.filter(customer => customer.id === this.customer.value.id);
      if (customer.length > 0) {
        this.formGroupWalkIn.get('name').setValue(customer[0].name) 
        this.walkcustomerId = customer[0].id
        this.walkcustomerName = customer[0].name
        if (customer[0].profilePhoto != null) {
          this.imgurl = customer[0].profilePhoto

        } else {
          this.imgurl = '/assets/img/avatars/avatar.png';
        }
      } else {
        this.walkcustomerId = 0
        this.formGroupWalkIn.get('name').setValue(customer[0].name) 
        this.walkcustomerName = this.customer.value.name
      }
    } else {
      debugger
      this.formGroupWalkIn.get('name').setValue(this.customer.value) 
      this.walkcustomerId = 0
      this.walkcustomerName = this.customer.value
    }


  }
  populateCustomerValue = () => {

    console.log(this.customerIdNameList.length)

    if (this.customerIdNameList.length > 0) {
      if (this.formGroupPass.get('customerId').value.id) {
        // this.customer.map(item => {
        //   this.passCustomerMobile = String(item.mobileNo);
        //   this.passCustomerName = this.formGroupPass.get('customerId').value.name
        //   this.customerImgpass = item.profilePhoto;
        //   debugger
        // })

        // this.formGroupPass.get('customerId').setValue(this.customerControl.value.id);
        // this.customerId= this.formGroupPass.get('customerId').value.id;
        // let a = this.formGroupPass.get('customerId').value.id;
        // this.formGroupPass.get('customerId').setValue(a);
        // debugger
      }
    }

  }

  getAllAccesories() {
    this.accessoriesService.getAll().subscribe((data: any) => {
      this.accessories = data.data;

      if (this.accessories) {
        this.accessories.map(item => {
          this.accessoriesList.push({ accessoriesName: item.name, amount: item.amount, id: item.id, quantity: 0, ticketId: 0, total: 0 })
        })
      }

      // if(this.accessories){
      //   this.accessories.map(item => {
      //     this.ticketJoinDto = this.formGroupWalkIn.get('ticketJoinDto') as FormArray;
      //     // this.ticketJoinDto.push(this.createItem());
      //     this.ticketJoinDto.push(this.createItem(item));
      //   })
      // }


    }, (err) => this.error_c(err)
    )
  }

  incrementsAccesories(items, i) {

    if (this.accessoriesListAdded.length > 0) {
      const index = this.accessoriesListAdded.findIndex(x => x.id == items.id);
      if (index > -1) {
        this.accessoriesListAdded.splice(index, 1);
      }
    }

    console.log(this.accessoriesListAdded)

    this.accessoriesList.find(item => item.id == items.id).quantity = Number(items.quantity) + 1;

    let total: number = Number(items.amount) * Number(items.quantity);
    this.accessoriesList.find(item => item.id == items.id).total = total;

    this.accessoriesListAdded.push({ accessoriesName: items.accessoriesName, amount: items.amount, id: items.id, quantity: items.quantity, ticketId: 0, total: total });
    this.totAmount();
  }

  decrementsAccesories(items, i) {
    if (Number(items.quantity) >= 1) {

      if (this.accessoriesListAdded.length > 0) {
        const index = this.accessoriesListAdded.findIndex(x => x.id == items.id);
        if (index > -1) {
          this.accessoriesListAdded.splice(index, 1);
        }
      }

      this.accessoriesList.find(item => item.id == items.id).quantity = Number(items.quantity) - 1;


      let total: number = Number(items.amount) * Number(items.quantity);

      this.accessoriesList.find(item => item.id == items.id).total = total;


      if (Number(items.quantity > 0)) {
        this.accessoriesListAdded.push({ accessoriesName: items.accessoriesName, amount: items.amount, id: items.id, quantity: items.quantity, ticketId: 0, total: total });
      }
    }
    this.totAmount();
  }

  startTimeChange(value) {
    var manDate = moment(value, "HH:mm:ss")
    this.inTime = manDate.format('hh:mm A');
    var b = manDate.add(this.formGroupWalkIn.get('minutes').value, 'minute');
    let mi = this.formGroupWalkIn.get('minutes').value;
    this.outTime = b.format('hh:mm A');
    this.endTime = b.format('HH:mm');
    this.formGroupWalkIn.get('endTime').setValue(this.endTime);
  }

  minutesDecrements() {

    if (this.qty >= 2) {

      this.qty = this.qty - 1;

      this.formGroupWalkIn.get('minutes').setValue(this.qty * Number(this.minutes));

      this.startTimeChange(this.formGroupWalkIn.get('startTime').value);
    }
    this.totAmount();
  }

  minutesIncrements() {

    this.qty = this.qty + 1;

    this.formGroupWalkIn.get('minutes').setValue(this.qty * Number(this.minutes));

    this.startTimeChange(this.formGroupWalkIn.get('startTime').value);
    this.totAmount();
  }

  new_Customer() {

    this.formGroupCustomer = this.fb.group({
      id: [0],
      name: ['', Validators.required],
      mobileNo: [null, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
      address: [null],
      emergencyContactNo: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
      profilePhoto: [''],
      dateOfBirth: [],
      // barCode: [],
      emergencyName: ['', Validators.required],
      age: []
    });
    this.formGroupCustomer.get('dateOfBirth').valueChanges.subscribe(value => this.dateOfBirthonChange(value));

  }

  changeTab(evt: any) {
    console.log(evt); // has nextId that you can check to invoke the desired function
  }

  RemoveProfileUrl() {
    this.imgurl = '/assets/img/avatars/avatar.png';
    this.webCam = false;
    this.snapshot = false;
    $("#takephoto").attr("disabled", false);
  }

  RemoveProfileUrl1() {
    this.imgurl = '/assets/img/avatars/avatar.png';
    this.webCam1 = false;
    this.snapshot1 = false;
    $("#takephoto1").attr("disabled", false);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info("received webcam image", webcamImage);
    this.webcamImage = webcamImage;
    this.webCam = false;
    this.snapshot = true;
    this.Condition();
  }

  public handleImage1(webcamImage1: WebcamImage): void {
    console.info("received webcam image", webcamImage1);
    this.webcamImage1 = webcamImage1;
    this.webCam1 = false;
    this.snapshot1 = true;
    this.Condition1();
  }


  Condition() {
    debugger
    if (this.webcamImage != null) {
      var bytearray = this.base64ToByteArray(this.webcamImage.imageAsBase64);
      var file = new File([new Blob(bytearray)], "profile.jpeg", { type: "image/jpeg" });
      this.file = file;
      var reader = new FileReader();
      reader.readAsDataURL(this.file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgurl = reader.result;
      
      }
    }
    this.webCam = false;
    this.snapshot = false;
  }

  Condition1() {
    debugger
    if (this.webcamImage1 != null) {
      var bytearray = this.base64ToByteArray(this.webcamImage1.imageAsBase64);
      var file = new File([new Blob(bytearray)], "profile.jpeg", { type: "image/jpeg" });
      this.file = file;
      var reader = new FileReader();
      reader.readAsDataURL(this.file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgurl = reader.result;
      
      }
    }
  }

  base64ToByteArray(base64String) {
    try {
      var sliceSize = 1024;
      var byteCharacters = atob(base64String);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);
      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);
        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      // console.log(byteArrays);

      return byteArrays;
    } catch (e) {
      console.log("Couldn't convert to byte array: " + e);
      return undefined;
    }


  }

  onFileChange(event) {
    debugger
    this.imgurl = '';
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      this.file = file;
      // console.log(file);
      var reader = new FileReader();
      reader.readAsDataURL(this.file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgurl = reader.result;
      }
    }
  }

  onFileChange1(event) {
    debugger
    this.imgurl = '';
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      this.file = file;
      // console.log(file);
      var reader = new FileReader();
      reader.readAsDataURL(this.file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgurl = reader.result;
      }
    }
  }

  onSelect(event) {

    // console.log(event);
    if (event.addedFiles.length !== 0) {
      $("#profilePhoto").html("");
      this.fileErr = false;
      this.files.push(...event.addedFiles);
    }
    if (event.rejectedFiles.length !== 0) {
      $("#profilePhoto").html("The size of selected file is greater than 10 MB");
      this.fileErr = true;
    }
  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    $("#profilePhoto").html("");
    this.fileErr = false;
  }


  openWebCam() {
    debugger
    this.imgurl = "";
    this.webCam = true;
    this.snapshot = false;
    $("#takephoto").attr("disabled", true);
    this.showNextWebcam(true);
  }

  openWebCam1() {
    debugger
    this.imgurl = "";
    this.webCam1 = true;
    this.snapshot1 = false;
    $("#takephoto1").attr("disabled", true);
    this.showNextWebcam1(true);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }


  public showNextWebcam1(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }


  public triggerSnapshot1(): void {
    this.trigger.next();
  }

  public get triggerObservable1(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable1(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }


  uploadPicHideWebCam() {
    this.webCam = false;
    this.snapshot = false;
    $("#takephoto").attr("disabled", false);
  }

  uploadPicHideWebCam1() {
    this.webCam1 = false;
    this.snapshot1 = false;
    $("#takephoto1").attr("disabled", false);
  }

  changeprofilePicClose() {
    this.imgurl = "";
    this.webcamImage = null;
    this.webCam = false;
    this.snapshot = false;
    $("#takephoto").attr("disabled", false);
  }

  new_Passform() {
    this.formGroupPass = this.fb.group({
      // name: ['', Validators.required],
      customerId: ['', Validators.required],
      subscriptionId: ['', Validators.required],
      startDate: [this.date, Validators.required],
      upTo: [''],
      schedulesId: ['', Validators.required],
      subscriptionAmount: ['', Validators.required],
      discount: [0],
      paymentType: ['UNPAID'],
      userId: [this.userId, Validators.required],
      userName: [this.userName, Validators.required]
    });
  }

  new_WalkInform() {
    this.formGroupWalkIn = this.fb.group({
      name: ['', Validators.required],
      ticketTotal: [0, Validators.required],
      // startTime: [this.inTime, Validators.required],
      // endTime: [this.endTime, Validators.required],
      // minutes: [this.minutes, Validators.required],
      schedulesId: ['', Validators.required],
      mobileNo: [null, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]]
    });
    this.accessoriesListAdded = [];
    this.accessoriesList = [];
    this.accessories.map(item => {
      this.accessoriesList.push({ accessoriesName: item.name, amount: item.amount, id: item.id, quantity: 0, ticketId: 0, total: 0 })
    })
    this.RemoveProfileUrl();
    this.qty = 1;
    this.totAmount();
  }
  reload() {
    window.location.reload()
  }

  createItem(item): FormGroup {

    return this.fb.group({
      accessoriesName: item.name,
      amount: item.amount,
      id: item.id,
      quantity: 0,
      ticketId: 0,
      total: 0
    });
  }

  get_rate() {
    this.settingRateService.getAll().subscribe((data: any) => {
      if (this.isEmptyObject(data.data[0])) {
        this.toastr.info("Please Add rates from Setting", "Info")
      } else {
        this.regularRate = data.data[0].regularDays;
        this.holidayRate = data.data[0].holidays;
        // this.minutes = data.data[0].miniutes;
        // this.formGroupWalkIn.get('minutes').setValue(data.data[0].miniutes);
        // this.startTimeChange(this.formGroupWalkIn.get('startTime').value);
        this.totAmount();
      }
    }, (err) => this.error_c(err))
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  ngAfterViewInit() {
    this.myInputField.nativeElement.focus();
    // if (this.date) {
    //   this.formGroupPass.get('startDate').setValue(this.date);
    // }
  }

  calcUptoDate(dateVal) {
    // var manDate = moment((dateVal ), "yyyy-MM-dd")
    // this.startdatePrint  = manDate.format('DD-MM-yyyy');
    this.startdatePrint = this.datePipe.transform(dateVal, 'dd-MM-yyyy');
    if (dateVal && this.subscriptionValidity) {
      // var manDate = moment((dateVal ), "yyyy-MM-dd")
      // this.startdatePrint  = manDate.format('DD-MM-yyyy');
      var manDate1 = moment((dateVal + 1), "yyyy-MM-dd")
      var b = manDate1.add(this.subscriptionValidity, 'day');
      let upto = b.format('yyyy-MM-DD');
      this.enddatePrint = b.format('DD-MM-yyyy');
      this.formGroupPass.get('upTo').setValue(upto);
      // console.log(upto)
      // debugger
    }
  }

  load_subscriptions() {
    this.subscriptionService.getAll().subscribe((data: any) => {
      this.subscriptionList = data.data;
      this.subscriptionData = [];
      if (this.subscriptionList) {
        this.subscriptionList.map(item => {
          const obj = {
            id: item.id,
            text: item.name
          }
          this.subscriptionData.push(obj);
        })
      }
      this.spinner.hide();
    },
      (err) => this.error_c(err)
    )
  }


  load_schedules() {
    this.scheduleService.getAll().subscribe((data: any) => {
      this.scheduleList = data.data;
      this.scheduleData = [];
      let manDate;
      let printS;
      let printO;
      if (this.scheduleList) {
        this.scheduleList.map(item => {
          manDate = moment(item.startTime, "HH:mm:ss")
          printS = manDate.format('hh:mm A');
          manDate = moment(item.endTime, "HH:mm:ss")
          printO = manDate.format('hh:mm A');

          const obj = {
            id: item.id,
            text: printS + ' TO ' + printO
          }
          this.scheduleData.push(obj);
        })
      }
      this.spinner.hide();
    },
      (err) => this.error_c(err)
    )

  }

  schedulesIdChange(val: string) {
    //this.spinner.show();
    this.scheduleData.map(item => {
      if (val == item.id) {
        this.scheduleTime = item.text;

      }
      else {
        this.spinner.hide();
      }
    },
      (err) => this.error_c(err)
    )
  }
  subscriptionChange(val: number) {
    //this.spinner.show();

    this.subscriptionData.map(item => {
      if (val == Number(item.id)) {
        this.passSubscription = item.text;

      }
    })

    this.subscriptionList.map(item => {
      if (val == item.id) {
        this.subHelp = true;
        this.formGroupPass.get('subscriptionAmount').setValue(item.amount);
        this.subscriptionType = item.type;
        this.subscriptionValidity = item.days;
        this.subscriptionAmount = item.amount;
        this.subscriptionDaysVisits = item.visitCount;
        this.calcUptoDate(this.formGroupPass.get('startDate').value);
        let discount = this.formGroupPass.get('discount').value | 0
        this.discountChange(discount)
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    },
      (err) => this.error_c(err)
    )
  }

  discountChange(value: number) {

    if (value) {
      if (this.subscriptionAmount < value) {
        this.toastr.info("Discount can't be greater than amount.");
      } else {
        let total = (this.subscriptionAmount) - (value) | 0;
        this.formGroupPass.get('subscriptionAmount').setValue(total);
      }
    }
  }

  error_c(err) {
    this.spinner.hide();
    if (String(err.error.message).length > 0 && !String(err.error.message) == undefined) {

      this.toastr.error(err.error.message, 'Alert!');

    } else {
      this.toastr.error("Technical Error.", 'Alert!');
    }
  }

  totAmount() {
    let rate = 0;
    if (this.rateType === "Regular") {
      rate = this.regularRate;

    } else {
      rate = this.holidayRate;
    }

    this.entryFess = this.qty * rate;
    let accessoriesTotal = 0;
    this.accessoriesListAdded.map(item => {
      accessoriesTotal = accessoriesTotal + Number(item.total);
    })
    this.ticketTotal = this.entryFess + accessoriesTotal;
    this.formGroupWalkIn.get('ticketTotal').setValue(this.ticketTotal);
  }

  changeStatus() {
    if (this.formGroupPass.get('paymentType').value === 'UNPAID') {
      this.paymentForm = this.fb.group({
        paidAmount1: [0,],
        paymentMode1: ['',],
        paymentDetails1: ['',],
      });
      this.payment = false
    } else {
      this.paymentForm = this.fb.group({
        paidAmount1: [0, Validators.required],
        paymentMode1: ['', Validators.required],
        paymentDetails1: ['', Validators.required],
      });
      this.payment = true
    }
  }
  saveWalkIn() {
    debugger
    let stime = new Date();
    let tm = moment(stime.toLocaleTimeString(), ["h:mm A"]);
    this.printTime = tm.format("HH:mm");
    console.log(this.accessoriesListAdded)
    let walkinAccesories: WalkinAccesories[] = [];
    this.accessoriesListAdded.map(items => {
      walkinAccesories.push({ accessoriesName: items.accessoriesName, amount: items.amount, id: 0, quantity: items.quantity, ticketId: 0, total: items.total });
    })

    let data = this.formGroupWalkIn.value;
    // delete data.minutes;
    // delete data.delete
    data.customerId = this.walkcustomerId
    data.name = this.walkcustomerName;
    data.userId = this.userId
    data.userName = this.userName
    data.ticketJoinDto = walkinAccesories;
    data.id = 0;
    const formData: FormData = new FormData();
    console.log(this.file);

    formData.append('photo', this.file);
    formData.append('ticketDtoJson  ', JSON.stringify(data));
    debugger
    console.log(this.files);
    console.log(data);

    this.ticketService.create(formData).subscribe(res => {

      if (res.success) {
        debugger
        this.toastr.success('Successfully Saved!', 'Notification!');
        this.walkQrCode = res.data.barcode;
        // this.walkInPrint.setValue(this.formGroupWalkIn.value);

        this.scheduleList.map(item => {
          if (item.id == res.data.schedulesId) {
            let manEDate = moment(item.endTime, "HH:mm:ss")
            let printE = manEDate.format('hh:mm A');
            let manSDate = moment(item.startTime, "HH:mm:ss")
            let printS = manSDate.format('hh:mm A');
            this.walkInPrint.get('endTime').setValue(printE);
            this.walkInPrint.get('startTime').setValue(printS);
          }
        })

        this.walkInPrint.get('walkQrCode').setValue(res.data.barcode);
        this.walkInPrint.get('name').setValue(this.formGroupWalkIn.get('name').value);
        this.walkInPrint.get('ticketTotal').setValue(this.formGroupWalkIn.get('ticketTotal').value);
        this.walkInPrint.get('schedulesId').setValue(this.formGroupWalkIn.get('schedulesId').value);
        this.walkInPrint.get('mobileNo').setValue(this.formGroupWalkIn.get('mobileNo').value);

        this.formGroupWalkIn.reset();
        this.customer.reset();
        this.accessoriesList.map(item => {
          item.quantity = 0;
        })
        this.accessoriesListAdded = [];
        this.webCam = false;
        this.snapshot = false;
        $("#takephoto").attr("disabled", false);
        setTimeout(() => {
          this.print();
        }, 1000);


      }
      else {
        this.toastr.error(res.message, 'Notification!');
      }

      this.spinner.hide();

    }, (err) => this.error_c(err)
    )



  }
  passSave() {
    if (this.isSavePass) {
      this.toastr.error("Pass Saved already.", "Please Print Or New");
      return
    }

    let stime = new Date();
    let tm = moment(stime.toLocaleTimeString(), ["h:mm A"]);
    this.printTime = tm.format("HH:mm");

    //this.spinner.show();
    // console.log(this.formGroupPass.get('customerId').value.id)
    this.getReceiptNo();
    let data = this.formGroupPass.value;
    data.customerId = this.formGroupPass.get('customerId').value.id;
    data.customerName = this.formGroupPass.get('customerId').value.name;
    this.passCustomerName = this.formGroupPass.get('customerId').value.name;

    data.visitCount = this.subscriptionDaysVisits;

    delete data.paymentType;
    delete data.upTo;
    data.days = this.subscriptionValidity;
    console.log(data);
    debugger

    this.assignSubscriptionService.create(data).subscribe(res => {
      this.passBarcode = res.data.barcode;
      debugger
      if (res.success) {

        if (this.payment) {

          let data = this.paymentForm.value;
          let dues = this.formGroupPass.get('subscriptionAmount').value;
          data.customerId = this.formGroupPass.get('customerId').value.id
          let amount = this.paymentForm.get('paidAmount1').value

          if (amount <= 0) {
            this.toastr.error("Amount can't be 0(zero).", "Check Amount");
            return
          }
          if (amount > dues) {
            this.toastr.error("Amount can't be greater than dues.", "Check Amount");
          } else {

            // paidAmount1: [0,],
            // paymentMode1: ['',],
            // paymentDetails1: ['',],

            let data2 = this.paymentForm.value;
            delete data2.paidAmount1;
            delete data2.paymentMode1;
            delete data2.paymentDetails1;

            data2.customerId = this.formGroupPass.get('customerId').value.id;
            data2.customerName = this.formGroupPass.get('customerId').value.name;
            data2.id = 0;
            data2.paidAmount = this.paymentForm.get('paidAmount1').value;
            data2.paymentDetails = this.paymentForm.get('paymentDetails1').value;
            data2.paymentMode = this.paymentForm.get('paymentMode1').value;
            data2.receiptDate = this.date;

            data2.receiptNo = this.receiptNo
            data2.userId = this.userId
            data2.userName = this.userName

            this.receiptService.create(data).subscribe(res => {
              if (res.success) {
                this.toastr.success('Successfully Saved!', 'Notification!');
                this.isSavePass = true;
                this.webCam1 = false;
                this.snapshot1 = false;
                $("#takephoto1").attr("disabled", false);
              }
              else {
                this.toastr.error(res.message, 'Notification!');
              }
              this.spinner.hide();
            }, (err) => this.error_c(err)
            )
          }


        } else {
          this.toastr.success('Successfully Saved!', 'Notification!');
          this.isSavePass = true;
        }
      }
      else {
        this.toastr.error(res.message, 'Notification!');
      }
      this.spinner.hide();
    }, (err) => this.error_c(err)
    )
  }
  getReceiptNo() {
    this.spinner.show()
    this.receiptService.getReceiptNo().subscribe(res => {
      this.receiptNo = res | 0;
      this.spinner.hide();
    }, (err) => this.error_c(err)
    )
  }
  onConsentFiles(event) {
    this.files = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files[i]);
    }
  }

  newCustomerSubmit() {
    //this.spinner.show();
    // this.new_Customer();
    let isdoc: boolean = true;

    for (var i = 0; i < this.files.length; i++) {
      if (isdoc) {
        if (this.files[i].size > 10485760) {
          isdoc = false
          this.toastr.error('Consent Document greater than 10MB', 'Image');
          this.spinner.hide();
          break
          return
        }
      }
    }

    if (this.file.size > 5242880) {
      this.toastr.error('Profile photo is greater than 5MB', 'Image');
      this.spinner.hide();
      return
    }

    const formData: FormData = new FormData();

    for (var i = 0; i < this.files.length; i++) {
      formData.append("files", this.files[i]);
    }
    console.log(this.file)

    formData.append('profilePhoto', this.file);
    formData.append('customerDto ', JSON.stringify(this.formGroupCustomer.value));

    this.customerService.create(formData).subscribe(res => {
      if (res.success) {
        this.toastr.success('Successfully Saved!', 'Notification!');
        this.closebutton.nativeElement.click();
      }
      else {
        this.toastr.error(res.message, 'Notification!');
      }

      this.spinner.hide();

    }, (err) => this.error_c(err)
    )

  }


  print(): void {
    let stime = new Date();
    let tm = moment(stime.toLocaleTimeString(), ["h:mm A"]);
    this.printTime = tm.format("HH:mm");

    let printContents, popupWin;
    printContents = document.getElementById('walkinPrint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Walk In</title>
          <style>
          .text-center {
            text-align: center;
          }
          
          .text-left {
            text-align: left;
          }
          
          .text-right {
            text-align: right;
          }

          .normal-lable{
            font-size: 8;
            text-align: center;
          }

          .centered-text {
            text-align: center;
            align-content: center;
          }
          .print-lable{
            font-weight: 500;
            font-size: 13px;
            font-family: 'Times New Roman';
          }
         
         

          .left-text {
            text-align: left;
          }

          .print-main{
            font-weight: bold;
            font-size: 13px;
            font-family: 'Times New Roman';
          }

          .QrCodePadding {
           padding-left:35x;
          }

        @page {
          size: 3.14961in 11.69in; 
          margin: .5in .5in .5in .5in; 
          mso-header-margin: .5in; 
          mso-footer-margin: .5in; 
          mso-paper-source: 0;
   }
     
          </style>
        </head>
    <body onload="window.print();">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }




  // printPass() {
  //   let stime = new Date();
  //   let tm = moment(stime.toLocaleTimeString(), ["h:mm A"]);
  //   this.printTime = tm.format("HH:mm");

  //   let printContents, popupWin;
  //   printContents = document.getElementById('passPrint').innerHTML;
  //   popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  //   popupWin.document.open();
  //   popupWin.document.write(`
  //     <html>
  //       <head>
  //         <title>Pass</title>
  //         <style>

  //         .print-lable{
  //           font-weight: 500;
  //           font-size: 13px;
  //           font-family: 'Times New Roman';
  //         }
  //         .print-main{
  //           font-weight: bold;
  //           font-size: 13px;
  //           font-family: 'Times New Roman';
  //         }

  //        .comp-lable {
  //           font-size: 8;
  //           text-align: center;
  //         }

  //         .comp-lable8 {
  //           font-size: 8;
  //           text-align: left;
  //         }

  //         .lable-pass{
  //           font-size: 9;
  //           font-family: 'Times New Roman';
  //         }

  //         .comp{
  //           float:right;
  //         }

  //         .left-text {
  //           text-align: left;
  //         }
  //         .left {
  //           left: 25px;
  //         }

  //         .right {
  //           right: 25px;
  //         }

  //         .center {
  //           text-align: center;
  //         }

  //         .bottom {
  //           position: absolute;
  //           bottom: 25px;
  //         }





  //         #card {
  //           border:solid 1px;
  //           padding: 0px;
  //         }

  //         #card img {

  //           float: left;
  //           border-radius: 5px;

  //           -webkit-filter: sepia(1);
  //           -moz-filter: sepia(1);
  //           filter: sepia(1);
  //         }

  //         #card h2 {
  //           font-family: courier;
  //           color: #333;
  //           margin: 0 auto;
  //           padding: 0;
  //           font-size: 10pt;
  //         }
  //         hr.new1 {
  //           border-top: 1px solid black;
  //         }

  //         #card span {
  //           font-family: Times New Roman;
  //         }         

  //       @page {
  //         size: 3.375in 2.12in; 
  //         mso-paper-source: 0;
  //  }

  //         </style>
  //       </head>
  //   <body onload="window.print();">${printContents}</body>
  //     </html>`
  //   );
  //   popupWin.document.close();
  // }


  printPass() {
    let stime = new Date();
    let tm = moment(stime.toLocaleTimeString(), ["h:mm A"]);
    this.printTime = tm.format("HH:mm");

    let printContents, popupWin;
    printContents = document.getElementById('passPrint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Pass</title>
          <style>
          
          .print-lable{
            font-weight: 500;
            font-size: 13px;
            font-family: 'Times New Roman';
          }
          .print-main{
            font-weight: bold;
            font-size: 13px;
            font-family: 'Times New Roman';
          }

         .comp-lable {
            font-size: 9pt;
            text-align: center;
          }

          .comp-lable8 {
            font-size: 8pt;
            text-align: left;
          }

          .lable-pass{
            font-size: 9;
            font-family: 'Times New Roman';
          }
 
          .comp{
            float:right;
          }
         
          .left-text {
            text-align: left;
          }
          .left {
            left: 25px;
          }
          
          .right {
            right: 25px;
          }
          
          .center {
            text-align: center;
          }
          
          .bottom {
            position: absolute;
            bottom: 25px;
          }
          
         
          
         
          
          #card {
            border:solid 1px;
            padding: 3px;
            max-width:460.8px;
            max-height:307.2px;
          }
          
          #card img {
         
            float: left;
            border-radius: 5px;
         
            // -webkit-filter: sepia(1);
            // -moz-filter: sepia(1);
            // filter: sepia(1);
          }
          
          #card h2 {
            font-family: courier;
            color: #333;
            margin: 0 auto;
            padding: 0;
            font-size: 10pt;
          }
          hr.new1 {
            border-top: 1px solid black;
          }

          .float-right{
            float:right;
          }

          .printPass{
            max-width:460.8px;
            max-height:307.2px;
          }
          
          #card span {
            font-family: Times New Roman;
          }         

        @page {
          // size: 4.8in 3in; 
          size: 8.27in 11.69in; 
          margin: .5in .5in .5in .5in; 
          mso-header-margin: .5in; 
          mso-footer-margin: .5in; 
          mso-paper-source: 0;
   }
     
          </style>
        </head>
    <body onload="window.print();">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }


}
