import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ScheduleModel } from '../../model/schedule-model';
import swal from 'sweetalert';
import { ScheduleService } from '../../service/schedule.service';

									
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  pageNo = 0;
  profile: File;
  btnaction: string = "Save";
  headingLable: string = "New";
  formGroup: FormGroup;
  id: number = 0;
  schedule: ScheduleModel[] = [];
  filterschedule: ScheduleModel[] = [];
  filterString = new FormControl('');
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private scheduleService: ScheduleService
  ) {
    this.my_form();
  }

  my_form() {
    this.formGroup = this.fb.group({
      id: [0],
      startTime: [null, Validators.required],
      endTime: [null, [Validators.required]]
    });
  }
  edit(data){
    this.formGroup.setValue(data);
  }

  ngOnInit(): void {
    this.spinner.show()
     this.getAll();
    this.spinner.hide()
  }

  getAll() {
    this.scheduleService.getAll().subscribe((data: any) => {
      this.schedule = data.data;
      this.filterschedule = data.data;
      debugger
    }, (err) => this.error_c(err)
    )
  }
  reset(){
    this.my_form();
  }

  delete(id, i) {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this Schedule?",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.spinner.show();
          const index = this.schedule.findIndex(x => x.id == id);
          this.scheduleService.delete(id).subscribe(res => {
            this.filterschedule.splice(i, 1);
            this.schedule.splice(index, 1);
            swal(
              "Schedule has been deleted!",
              {
                icon: "success",
                timer: 1500,
                buttons: {
                  ok: false
                }
              });
          }, (err) => this.error_c(err)
          );
          this.spinner.hide();
        }

      });
  }

  
  pageNoChange($event) {
    this.pageNo = $event - 1;
  }
  
  error_c(err) {
    this.spinner.hide();
    if (String(err.error.message).length > 0 && !String(err.error.message) == undefined) {
      debugger
      this.toastr.error(err.error.message, 'Alert!');
    }
    else {
      this.toastr.error("Technical Issue", 'Alert!');
    }
  }

  onSubmit(): void {
    this.spinner.show();
    this.id = this.formGroup.get('id').value;
    debugger
    if (this.id > 0) {
      this.scheduleService.update(this.id, this.formGroup.value).subscribe(res => {
        if (res.success) {
          this.toastr.success('Successfully Updated!', 'Notification!');
          this.getAll();
          this.my_form();

        }
        else {
          this.toastr.error(res.message, 'Notification!');
        }
        this.spinner.hide();
      }, (err) => this.error_c(err)
      )
    }
    else {
      this.scheduleService.create(this.formGroup.value).subscribe(res => {
        debugger
        if (res.success) {
          this.toastr.success('Successfully Saved!', 'Notification!');
          this.getAll();
          this.my_form();
        }
        else {
          this.toastr.error(res.message, 'Notification!');
        }
        this.spinner.hide();
      }, (err) => this.error_c(err)
      )
    }
  }

}
