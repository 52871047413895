import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Receipt } from '../../../model/receipt';
import { ReceiptService } from '../../../service/receipt.service';

@Component({
  selector: 'app-all-receipt',
  templateUrl: './all-receipt.component.html',
  styleUrls: ['./all-receipt.component.scss'],
  providers: [DatePipe]
})
export class AllReceiptComponent implements OnInit {
  
  receiptList: any = [];

  filtered;
  filterString = "";
  searchControl = new FormControl();
  searchForm: FormGroup;
  myDate = new Date();
  date: string = '';
  filterReceipt: Receipt[] = [];
  constructor(
    private spinner: NgxSpinnerService,
    private receiptService: ReceiptService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private toastr: ToastrService,
  ) {
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.new_searchForm();
  }

  ngOnInit(): void {
    this.serachSubmitdate();
    this.searchControl.valueChanges.subscribe(x => this.onFilterChange());
  }
  new_searchForm() {
    this.searchForm = this.fb.group({
      fromDate: [this.date, Validators.required],
      toDate: [this.date, Validators.required]
    });
  }

  serachSubmitdate(){
    this.spinner.show()

    console.log(this.searchForm.value)
    this.receiptService.getallData(this.searchForm.get('fromDate').value,this.searchForm.get('toDate').value).subscribe(data => {
      this.receiptList = data.data;
      this.filtered = data.data;
      this.spinner.hide()
    });

    this.onFilterChange();
    this.spinner.hide()
  }


  onFilterChange() {
    
    this.filtered = this.receiptList.filter((invoice) => this.isMatch(invoice));
  }

  isMatch(item) {
    
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {

      return item.toString().toLowerCase().indexOf(this.searchControl.value.toLowerCase()) > -1
    }
  }

  serachSubmit() {
    // debugger


    // this.receiptService.().subscribe(data => {
    //   this.receiptList = data.content;
    //   this.filtered = data.content;
    //   this.spinner.hide()
    // });

   
  }

}
