import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../service/token-storage.service';
import { navItems } from '../../_nav';
import { Users } from '../../model/users';
import { FormControl } from '@angular/forms';
import { CompanyProfileService } from '../../service/customer.service.spec';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})

export class DefaultLayoutComponent {
  userRole: string;
  index: number;
  navI = []
  navItems: any[];
  userData: any;
  user: Users[] = [];
  userName: string;
  rateType = new FormControl(false);
  rate;
  imgurl: any;
  constructor(private tokenStorage: TokenStorageService, private router: Router,
    private companyProfileService: CompanyProfileService) {
    this.companyProfileService.getAll().subscribe((data: any) => {
      debugger
      if (data.data[0]) {
        // if (data.data[0].logo == '' || data.data[0].logo == null) {
        //   this.imgurl = this.formGroupProfile.get('logo').value
        // } else {
          this.imgurl = data.data[0].logo
        // }
      }
    })
    this.get();
    this.userName = this.tokenStorage.getUser().name;
  this.rate = this.tokenStorage.getRateType()
    if (this.rate  === 'Regular' || this.rate  == null) {
      this.rateType.setValue(false);
    }else{
      this.rateType.setValue(true);
    }
    
  }
  public sidebarMinimized = false;
  get() {
    this.userRole = window.sessionStorage.getItem('role');
    // console.log("Test: " + JSON.stringify(navItems))
    this.navI = navItems.filter(item => (item.role ? item.role.indexOf(this.userRole) > -1 : item));
    this.navItems = this.navI;
  }
  // getUserName() {
  //    console.log(this.tokenStorage.getUser().name);
  // }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.tokenStorage.signOut();
    this.router.navigateByUrl('/login');
  }

  rateChange(event) {
    if(event.target.checked){
      this.tokenStorage.saveRateType("Holiday");
    }else{
      this.tokenStorage.saveRateType("Regular");
    }
    window.location.reload();
  }
}

