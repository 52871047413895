import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    // const token = localStorage.getItem('token');
    const userRole = window.sessionStorage.getItem('role');
    // decode the token to get its payload
    if (userRole !== expectedRole) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
  
}
