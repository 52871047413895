import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CustomerModel } from '../../model/customer-model';
import { CustomerService } from '../../service/customer.service';
import { ReportService } from '../../service/report.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [DatePipe]
})

export class ReportComponent implements OnInit {
  @ViewChild('instance2', { static: true }) instance2: NgbTypeahead;

  customerIdNameList: { id: number, name: string }[] = [];
  customer: CustomerModel[] = [];
  userList: any;

  pageNo = 0;
  itemsPerPage = 10;

  fromDate: string;
  toDate: string;
  customerId: number = 0;
  type: string;
  userId: number;

  reportList: any = [];
  reportLength: any = [];

  customerControl = new FormControl();
  focus2$ = new Subject<string>();
  click2$ = new Subject<string>();
  searchForm: FormGroup;
  myDate = new Date();
  public exampleData: Array<Select2OptionData>;
  public userData: Array<Select2OptionData>;

  optionsSelect = {
    placeholder: "Select..",
    allowClear: true,
    width: "100%"
  }

  date: string = '';
  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private userService: UserService,
    private reportService: ReportService
  ) {
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.new_searchForm();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.load_users();
    this.searchReport(0);
  }

  pageNoChange($event) {
    this.pageNo = $event - 1;
    this.searchReport(this.pageNo);
  }

  load_users() {
    this.userService.getAll().subscribe((data: any) => {
      this.userData = [];
      this.userList = data.data;
      const obj = {
        id: "0",
        text: 'All'
      }
      this.userData.push(obj);
      if (this.userList) {
        this.userList.map(item => {
          const obj = {
            id: item.id,
            text: item.name
          }
          this.userData.push(obj);
        })
      }
      this.spinner.hide();
    },
      (err) => this.error_c(err)
    )
  }

  searchReport(pageNo) {



    this.pageNo = pageNo;
    this.fromDate = this.searchForm.get('fromDate').value
    this.toDate = this.searchForm.get('toDate').value
    this.userId = this.searchForm.get('userId').value
    this.type = this.searchForm.get('type').value

    this.reportService.getallData(this.pageNo, this.itemsPerPage, this.fromDate, this.toDate, this.customerId, this.type, this.userId).subscribe(data => {
      this.reportList = data.data;
      this.reportLength = data.data.length;
      debugger
      this.spinner.hide()
    }, (err) => this.error_c(err)
    );
  }

  new_searchForm() {
    this.searchForm = this.fb.group({
      fromDate: [this.date],
      toDate: [this.date],
      type: [''],
      userId: [0],
    });
  }

  search: OperatorFunction<string, readonly { id, name }[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click2$.pipe(filter(() => !this.instance2.isPopupOpen()));
    const inputFocus$ = this.focus2$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term.length < 2 ? []
        : this.getcustomer(term).filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
    );
  }
  getcustomer(term) {
    //  if (term.length > 3){
    console.log(term.length)
    this.customerService.getbyterm(term).subscribe((data: any) => {
      this.customer = data.data;
      this.customerIdNameList = [];

      this.customer.map(item => {
        let p = {
          'id': item.id,
          'name': item.name
        }
        this.customerIdNameList.push(p);
      })
      this.spinner.hide();
    }, (err) => this.error_c(err))
    return this.customerIdNameList;
    // }
  }

  formatterCustomer = (x: { name: string }) => x.name;


  populatecustomerValue = () => {
    if (this.customerIdNameList.length > 0) {
      if (this.customerControl.value.id) {
        this.customerId = this.customerControl.value.id
      } else {
        this.customerId = 0
      }
    } else {
      this.customerId = 0
    }
  }

  error_c(err) {
    this.spinner.hide();
    if (String(err.error.message).length > 0 && !String(err.error.message) == undefined) {
      debugger
      this.toastr.error(err.error.message, 'Alert!');
    }
    else {
      this.toastr.error("Technical Issue", 'Alert!');
    }
  }

}
