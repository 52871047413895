import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from '../../app/common/global';
import { ResponseData } from '../model/response';
import { TicketPass } from '../model/ticket-pass';
// import { AccessoriesModel } from '../model/customer-model';

@Injectable({
  providedIn: 'root'
})
export class AssignSubscriptionService {

  constructor(private httpClient: HttpClient, private global: Globals) { }

  create(data: any): Observable<any> {
    return this.httpClient.post(this.global.api_Url + this.global.assign_subscription, data)
  }
  // getAll() {
  //   return this.httpClient.get<TicketPass[]>(this.global.api_Url + this.global.assign_subscription)
  //   // .pipe(
  //   //   catchError(this.errorHandler)
  //   //)
  // }

  getDuesById(customerId): Observable<any> {
    return this.httpClient.get(this.global.api_Url + this.global.assign_subscription + '/' + this.global.get_due_by_customer + '?customerId=' + customerId)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }

  getByParam(pageNo, itemsPerPage, customerId,status,userId): Observable<any> {

    return this.httpClient.get(this.global.api_Url + this.global.assign_subscription + '/' + this.global.get_subscription_by_customer_user_status +  '?pageNo=' + pageNo + '&pageSize=' + itemsPerPage + '&customerId=' + customerId + '&status=' + status + '&userId=' + userId);

    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }

  getAll(pageNo, itemsPerPage): Observable<any> {

    return this.httpClient.get(this.global.api_Url + this.global.assign_subscription  +  '?pageNo=' + pageNo + '&pageSize=' + itemsPerPage);

    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }


  delete(id: number) {
    //  debugger
    return this.httpClient.delete<TicketPass>(this.global.api_Url + this.global.assign_subscription + '/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    // )
  }

  update(id, data) {
    return this.httpClient.put<ResponseData>(this.global.api_Url + this.global.assign_subscription + '/' + id, data)
  }

  getbyid(id: any) {
    return this.httpClient.get<TicketPass[]>(this.global.api_Url + this.global.assign_subscription + '/' + id)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
