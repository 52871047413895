import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from '../../app/common/global';
import { ResponseData } from '../model/response';
import { Rates } from '../model/rates';
// import { ScheduleModel } from '../model/schedule-model';

// import { ScheduleModel } from '../model/customer-model';


@Injectable({
  providedIn: 'root'
})
export class SettingsRateService {

  constructor(private httpClient: HttpClient, private global: Globals) { }

  create(data: any) {
    return this.httpClient.post<ResponseData>(this.global.api_Url + this.global.rate, data)
  }
  getAll() {
    return this.httpClient.get<Rates[]>(this.global.api_Url + this.global.rate)
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }

  update(id, data) {
    return this.httpClient.put<ResponseData>(this.global.api_Url + this.global.rate + '/' + id, data)
  }


  // delete(id: number) {
  //   //  debugger
  //   return this.httpClient.delete<Rates>(this.global.api_Url + this.global.rate + '/' + id)
  //   // .pipe(
  //   //   catchError(this.errorHandler)
  //   // )
  // }



  // getbyid(id: any) {
  //   return this.httpClient.get<Rates[]>(this.global.api_Url + this.global.rate + '/' + id)
  //   // .pipe(
  //   //   catchError(this.errorHandler)
  //   //)
  // }


  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
