import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { ScheduleModel } from './model/schedule-model';
import { AuthGuard } from './service/auth.guard';
import { RoleGuard } from './service/role.guard';
import { EntryPointComponent } from './views/entry-point/entry-point.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ReportComponent } from './views/report/report.component';
import { ScheduleReportComponent } from './views/schedule-report/schedule-report.component';
import { ScheduleComponent } from './views/schedule/schedule.component';
import { SettingComponent } from './views/setting/setting.component';
import { TicketComponent } from './views/ticket/ticket.component';
import { UsersComponent } from './views/users/users.component';
// import { AuthGuard } from ''

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        canActivate: [AuthGuard],
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'customer',
        loadChildren: () => import('./views/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'subscriptions',
        loadChildren: () => import('./views/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'accessories',
        loadChildren: () => import('./views/accessories/accessories.module').then(m => m.AccessoriesModule)
      }, 
      {
        canActivate: [AuthGuard,RoleGuard],
        path: 'schedule',
        component : ScheduleComponent,
        data: { 
          expectedRole: 'ADMIN'
        }
      },
      {
        canActivate: [AuthGuard,RoleGuard],
        path: 'setting',
        component : SettingComponent,
        data: { 
          expectedRole: 'ADMIN'
        }
      },
      {canActivate: [AuthGuard],
        path: 'ticket',
        component : TicketComponent,
        data: { 
          expectedRole: 'ADMIN'
        }
      },
      {
        canActivate: [AuthGuard],
        path: 'entry-point',
        component : EntryPointComponent
      },
      {
        canActivate: [AuthGuard,RoleGuard],
        path: 'users',
        component : UsersComponent,
        data: { 
          expectedRole: 'ADMIN'
        }
      },
      {
        canActivate: [AuthGuard],
        path: 'receipt',
        loadChildren: () => import('./views/receipt/receipt.module').then(m => m.ReceiptModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'report',
        component: ReportComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'schedule-report',
        component: ScheduleReportComponent
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
