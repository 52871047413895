import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from '../../app/common/global';
import { CustomerModel } from '../model/customer-model';
import { ResponseData } from '../model/response';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private httpClient: HttpClient, private global: Globals) { }


  getAll(pageNo, itemsPerPage, userId): Observable<any> {
    if (userId !== null) {
      return this.httpClient.get(this.global.api_Url + this.global.report + '?pageNo=' + pageNo + '&pageSize=' + itemsPerPage + '&userId=' + userId);
    } else {
      return this.httpClient.get(this.global.api_Url + this.global.report + '?pageNo=' + pageNo + '&pageSize=' + itemsPerPage);
    }
  }

  getScheduleReport(fromDate,toDate):Observable<any>{
    return this.httpClient.get(this.global.api_Url + this.global.report + '/' + this.global.schedule_report + '?fromDate=' + fromDate + '&toDate=' + toDate);
  }

  getallData(pageNo, itemsPerPage, fromDate, toDate, customerId, type, userId): Observable<any> {
    return this.httpClient.get(this.global.api_Url + this.global.report + '/' + this.global.report  + '?fromDate=' + fromDate + '&toDate=' + toDate + '&pageNo=' + pageNo + '&pageSize='+ itemsPerPage  + '&customerId='+ customerId + '&type='+ type + '&userId='+ userId);
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
