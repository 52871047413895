import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ScheduleModel } from '../../model/schedule-model';
import swal from 'sweetalert';
import { UserService } from '../../service/user.service';
import { Users } from '../../model/users';
import { ConfirmPasswordValidator } from './confirm-password.validator';
// import { ScheduleService } from '../../service/schedule.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  pageNo = 0;
  profile: File;
  btnaction: string = "Save";
  showConfirm: boolean = false;
  headingLable: string = "New";
  formGroup: FormGroup;
  id: number = 0;
  users: Users[] = [];
  filterusers: Users[] = [];
  filterString = new FormControl('');
  confirmPassword = new FormControl('');
  form: FormGroup = new FormGroup({});
  @ViewChild('closeaddForm') closeaddForm: ElementRef;
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.my_form();
  }

  my_form() {
    // this.formGroup = this.fb.group({
    //   id: [0],
    //   name: [null, Validators.required],
    //   address: [null, [Validators.required]],
    //   mobileNo: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
    //   password: [null, [Validators.required]],
    //   confirmPassword: [null, [Validators.required]]
    // });

    this.formGroup = this.fb.group({
      id: [0],
      name: [null, Validators.required],
      address: [],
      mobileNo: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: ConfirmPasswordValidator.MatchPassword,
    });


  }
  get f() {
    return this.form.controls;
  }
  edit(data) {
    data.confirmPassword = '';
    data.password = '';
    this.formGroup.setValue(data);
  }

  ngOnInit(): void {
    this.formGroup.get('password').valueChanges.subscribe(x => this.passwordChange(x));
    this.formGroup.get('confirmPassword').valueChanges.subscribe(x => this.confirmPasswordChange(x));
    this.filterString.valueChanges.subscribe(x => this.applyFilter(x));

    this.spinner.show()
    this.getAll();
    this.spinner.hide()
  }

  passwordChange(password: string) {
    let cpwd = this.formGroup.get('confirmPassword').value;
    if (password === cpwd) {
      this.showConfirm = false;
    } else {
      this.showConfirm = true;
    }
  }

  confirmPasswordChange(cpassword: string) {
    let pwd = this.formGroup.get('password').value;
    if (cpassword === pwd) {
      this.showConfirm = false;
    } else {
      this.showConfirm = true;
    }

  }

  getAll() {
    this.userService.getAll().subscribe((data: any) => {
      this.users = data.data;
      this.filterusers = data.data;
      debugger
    }, (err) => this.error_c(err)
    )
  }
  reset() {
    this.my_form();
  }
  delete(id, i) {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this User?",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.spinner.show();
          const index = this.users.findIndex(x => x.id == id);
          this.userService.delete(id).subscribe(res => {
            this.filterusers.splice(i, 1);
            this.users.splice(index, 1);
            swal(
              "Schedule has been deleted!",
              {
                icon: "success",
                timer: 1500,
                buttons: {
                  ok: false
                }
              });
          }, (err) => this.error_c(err)
          );
          this.spinner.hide();
        }

      });
  }


  pageNoChange($event) {
    this.pageNo = $event - 1;
  }

  error_c(err) {
    this.spinner.hide();
    if (String(err.error.message).length > 0 && !String(err.error.message) == undefined) {
      debugger
      this.toastr.error(err.error.message, 'Alert!');
    }
    else {
      this.toastr.error("Technical Issue", 'Alert!');
    }
  }

  onSubmit(): void {
    this.spinner.show();
    let pwd: string = this.formGroup.get('password').value;
    let conpwd: string = this.formGroup.get('confirmPassword').value;
    if (pwd === conpwd) {

      this.id = this.formGroup.get('id').value;
      debugger
      if (this.id > 0) {
        this.userService.update(this.id, this.formGroup.value).subscribe(res => {
          if (res.success) {
            this.toastr.success('Successfully Updated!', 'Notification!');
            this.getAll();
            this.my_form();
            this.closeaddForm.nativeElement.click();
          }
          else {
            this.toastr.error(res.message, 'Notification!');
          }
          this.spinner.hide();
        }, (err) => this.error_c(err)
        )
      }
      else {
        this.userService.create(this.formGroup.value).subscribe(res => {
          debugger
          if (res.success) {
            this.toastr.success('Successfully Saved!', 'Notification!');
            this.getAll();
            this.my_form();
            this.closeaddForm.nativeElement.click();
          }
          else {
            this.toastr.error(res.message, 'Notification!');
          }
          this.spinner.hide();
        }, (err) => this.error_c(err)
        )
      }
    } else {
      this.toastr.error("Password doesn't match.", 'Notification!');
    }
  }
  applyFilter(filterValue: string) {
    this.spinner.show();
    this.pageNo = 0;

    let filterValueLower = filterValue.toLowerCase().trim();
    // debugger
    if (filterValueLower === "") {
      this.filterusers = this.users;
    }
    else {
      this.filterusers = this.users.filter((item) => item.name.toLowerCase().includes(filterValueLower))
    }
    this.spinner.hide();
  }
}
